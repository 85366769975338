<template>
	<div class="">
		<div class="page-content">
			<el-image style="height: 180px; width: 100%" :src="require('@/assets/images/about.jpg')"></el-image>
		</div>
		<div class="page-content top">
			<div style="margin-bottom: 40px;">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>关于我们</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="num" style="position: absolute;"></div>
			<div id="" style="margin-left: 40px;">
				联系邮箱
			</div>
			<div id="" style="margin-top: 40px;margin-left: 30px;">
				
				<div style="line-height: 2px;margin-top: 30px;">
					<p><i class="el-icon-menu"></i> 期刊/出版社合作：</p>
					<p style="font-size: 14px;margin-left: 20px;color: #767288;">
						<span>关于所有期刊的合作咨询，请联系我们：</span>
						<span>
							<el-link :underline="false" style="color: #2697ff;">service@iamset.cn</el-link>
						</span>
					</p>
				</div>
				<div style="line-height: 2px;margin-top: 30px;">
					<p><i class="el-icon-menu"></i> 产品业务咨询:</p>
					<p style="font-size: 14px;margin-left: 20px;color: #767288;">
						<span>关于所有产品相关的业务咨询，请联系我们：</span>
						<span>
							<el-link :underline="false" style="color: #2697ff;">contact@iamset.cn</el-link>
						</span>
					</p>
				</div>
			</div>
			<el-divider style="margin-top: 40px;"></el-divider>
			<div id="" style="margin-top: 40px;">
				<div class="num" style="position: absolute;"></div>
				<div id="" style="margin-left: 40px;">
					社交媒体
				</div>
				<div id="" style="display: flex;margin-left: 30px;margin-top: 30px;">
					<div style="margin-right: 30px;text-align: center;line-height: 0px;">
						<p style="color: #767288;font-size: 14px;">IAMSET公众号</p>
						<el-image :src="require('@/assets/images/service.jpg')" style="width: 168px; height: 168px">
						</el-image>
					</div>
				</div>
			</div>
			<el-divider style="margin-top: 40px;"></el-divider>
			<div style="margin-left: 30px;">
				<p><span style="color: #767288;font-size: 14px;">如您还有其他任何问题，请发送邮件至</span><span>
						<el-link :underline="false" style="color: #2697ff;">service@iamset.cn</el-link>
					</span></p>
				<p style="color: red;font-size: 14px;">
					<span>郑重声明：本站所发布信息来源于网络，仅供科研服务、学习与研究使用，版权与著作权归原作者所有。</span></p>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		props: ['searchVal'],
		data() {
			return {
			};
		},

		methods: {},
		watch: {
			searchVal: function(val) {
				let paramsMap={
					headSearch:val
				}
				this.$router.push({
					name: "journalEija",
					params: paramsMap
				});
			}
		},
	};
</script>

<style lang="scss" scoped>
	.num {
		text-align: center;
		margin-right: 15px;
		color: white;
		width: 22px;
		height: 22px;
		background-image: linear-gradient(90deg, #1b5faa 0%, #00b4e4 100%),
			linear-gradient(#d40016, #d40016);
		background-blend-mode: normal, normal;
	}
</style>
